/* eslint-disable react/jsx-key */
import React, { Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';

import FullScreenLoading from '@/components/common/FullScreenLoading';
import ErrorBoundary from '@/components/ErrorBoundary';
import LegacyCSSProvider from '@/components/LegacyCSSProvider';

import { useGQLUserAndOrgQuery } from '../graphql/generated';
import { RequireAuth, RequireLoggedOut } from '../routing/auth';
import { FeatureFlagClient } from '../utils/featureFlagsClient';
import AwaitingApproval from './auth/AwaitingApproval';
import RejectedByAdmin from './auth/RejectedByAdmin';
import ExternalUrlRedirect from './ExternalUrlRedirect';
import HomeContainer from './home/HomeContainer';
import Home from './home/pages/Home';
// The homepage actually needs these styles. We need to load them here because
// we are lazy loading the Dashboard module which is where the original
// importing occurs. There's probably a better solution than this quick and
// dirty one. E.g. move the necessary styles to home.css
import './dashboard/Dashboard.css';

function lazyLoad(path: string) {
  // we must do this interpolation here for lazy loading to work properly for
  // some reason. See https://stackoverflow.com/a/73359606
  return React.lazy(async () => import(`${path}`));
}

const Blog = lazyLoad('./home/pages/Blog');
const BlogPost = lazyLoad('./home/pages/BlogPost');
const TermsOfUse = lazyLoad('./legal/TermsOfUse');
const PrivacyPolicy = lazyLoad('./legal/PrivacyPolicy');
const Enforcement = lazyLoad('./home/pages/Enforcement');
const ModerationConsole = lazyLoad('./home/pages/ModerationConsole');
const InternalLabeling = lazyLoad('./internal/labeling/InternalLabeling');
const InternalImageRendering = lazyLoad(
  './internal/labeling/InternalImageRendering',
);
const InternalItemLabeling = lazyLoad(
  './internal/labeling/InternalItemLabeling',
);
const InternalModelTraining = lazyLoad(
  './internal/model_training/InternalModelTraining',
);
const Compliance = lazyLoad('./home/pages/Compliance');
const About = lazyLoad('./home/pages/About');
const AI = lazyLoad('./home/pages/AI');
const SampleViewer = lazyLoad('./internal/labeling/ExamineSample');
const Demo = lazyLoad('./dashboard/models/demo/Demo');
const Login = lazyLoad('./auth/Login');
const ForgotPassword = lazyLoad('./auth/forgot_password/ForgotPassword');
const ResetPassword = lazyLoad('./auth/forgot_password/ResetPassword');
const SignUp = lazyLoad('./auth/SignUp');
const LoginSSO = lazyLoad('./auth/LoginSSO');
/**
 * This is the container for the React app. All React
 * components that render an entire webpage should be
 * listed here, along with their URL paths.
 *
 * Important: For any webpage that should only be visible to authenticated
 * users, they should be wrapped in a <RequireAuth /> instead of
 * a normal <Route />.
 *
 * Similarly, if any screens should only be visible to logged out users,
 * they should be wrapped in a <RequireLoggedOut /> tag.
 */

export default function App() {
  const { data } = useGQLUserAndOrgQuery();
  const user = data?.me;

  useEffect(() => {
    // TODO: reset some of these identities if the user logs out?
    // (Would using `!loading && user == null`.)
    if (user != null) {
      FeatureFlagClient.updateUser({ key: user.id, email: user.email });
      // Nb: can add other user attributes here too for filtering in logrocket
      // dashboard, like org. But these are stored at the user level, so this
      // shouldn't include temporary/session-specific things like A/B test group.
      window.LogRocket?.identify(user.id, { email: user.email });
    }
  }, [user]);

  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <AppWrapper />,
        children: [
          {
            path: '',
            element: <HomeContainer />,
            children: [
              { path: '', element: <Home /> },
              { path: 'terms', element: <TermsOfUse /> },
              { path: 'privacy', element: <PrivacyPolicy /> },
              { path: 'about', element: <About /> },
              { path: 'ai', element: <AI /> },
              { path: 'compliance', element: <Compliance /> },
              { path: 'enforcement', element: <Enforcement /> },
              { path: 'console', element: <ModerationConsole /> },
              { path: 'blog', element: <Blog /> },
              { path: 'blog/:slug', element: <BlogPost /> },
            ],
          },
          {
            path: 'careers',
            element: (
              <ExternalUrlRedirect to="https://protego.notion.site/Cove-Careers-cf8b15809fed4160a09e54feb5f1c929" />
            ),
          },
          {
            path: 'docs',
            element: <ExternalUrlRedirect to="https://docs.getcove.com/" />,
          },
          {
            path: 'login',
            element: (
              <RequireLoggedOut>
                <Login />
              </RequireLoggedOut>
            ),
          },
          {
            path: 'login/saml/callback',
            // This endpoint should only be accessed with POST requests during SAML
            // authentication. So if a user tries navigating to it in the browser, we
            // just redirect them back to the getcove.com landing page.
            element: <Navigate replace to="../../.." />,
          },
          {
            path: 'login/sso',
            element: (
              <RequireLoggedOut>
                <LoginSSO />
              </RequireLoggedOut>
            ),
          },
          {
            path: 'forgot_password',
            element: (
              <RequireLoggedOut>
                <ForgotPassword />
              </RequireLoggedOut>
            ),
          },
          {
            path: 'reset_password/:token?',
            element: (
              <RequireLoggedOut>
                <ResetPassword />
              </RequireLoggedOut>
            ),
          },
          {
            path: 'signup/:token?',
            element: (
              <RequireLoggedOut>
                <SignUp />
              </RequireLoggedOut>
            ),
          },
          {
            path: 'awaiting_approval',
            element: (
              <RequireAuth>
                <AwaitingApproval />
              </RequireAuth>
            ),
          },
          {
            path: 'rejected',
            element: (
              <RequireAuth>
                <RejectedByAdmin />
              </RequireAuth>
            ),
          },
          {
            path: 'demo',
            element: (
              <RequireAuth>
                <ErrorBoundary>
                  <Demo />
                </ErrorBoundary>
              </RequireAuth>
            ),
          },
          // Redirects
          {
            path: 'rules',
            element: <Navigate replace to="../dashboard/rules" />,
          },
          {
            path: 'actions',
            element: <Navigate replace to="../dashboard/actions" />,
          },
          {
            path: 'items_types',
            element: <Navigate replace to="../dashboard/item_types" />,
          },
          {
            path: 'content_types',
            element: <Navigate replace to="../dashboard/item_types" />,
          },
          {
            path: 'settings',
            element: <Navigate replace to="../dashboard/settings" />,
          },
          // Internal routes grouped under single error boundary
          {
            path: 'internal',
            element: (
              <RequireAuth>
                <ErrorBoundary containedInLayout>
                  <Outlet />
                </ErrorBoundary>
              </RequireAuth>
            ),
            children: [
              { path: 'training', element: <InternalModelTraining /> },
              { path: 'labeling_items', element: <InternalItemLabeling /> },
              { path: 'image_rendering', element: <InternalImageRendering /> },
              { path: 'labeling', element: <InternalLabeling /> },
              { path: 'examine_sample_data', element: <SampleViewer /> },
            ],
          },
        ],
      },
    ],
    {
      async patchRoutesOnNavigation({ path, patch }) {
        if (path.startsWith('/dashboard')) {
          const { DashboardRoutes } = await import('./dashboard/Dashboard');
          patch(null, [DashboardRoutes(data?.myOrg?.hasMrtAccess ?? false)]);
        } else if (path.startsWith('/training')) {
          const { ModelTrainingRoutes } = await import(
            './dashboard/models/training/TrainingPipeline'
          );
          patch(null, [ModelTrainingRoutes(data?.myOrg?.id)]);
        }
      },
    },
  );

  return (
    <div className="bg-[#F9F9F9] flex flex-col w-full h-full bottom-0 relative">
      <RouterProvider router={router} />
    </div>
  );
}

function AppWrapper() {
  const loadingFallback = <FullScreenLoading />;
  return (
    <LegacyCSSProvider>
      <Suspense fallback={loadingFallback}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </LegacyCSSProvider>
  );
}
